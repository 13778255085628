<template>
  <div class="mywallet">
    <v-container fluid>
      <v-row dense>
        <v-col align="center">
          <h1>My Wallet</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            max-width="100%"
            type="table"
          ></v-skeleton-loader>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Date
                  </th>
                  <th class="text-left">
                    Source
                  </th>
                  <th class="text-left">
                    Type
                  </th>
                  <th class="text-left">
                    Amount 
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td>{{ formatDateTimeFull(item.created_at) }}</td>
                  <td>{{ item.source }}</td>
                  <td>{{ debitOrCredit(item.amount) }}</td>
                  <td :class="debitOrCredit(item.amount).toLowerCase()">$ {{ item.amount }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="3">Total: </th>
                  <td>$ {{totalAmount}}</td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <stripe-checkout
            ref="checkoutRef"
            mode="payment"
            pk="pk_test_51K7SdGIKZFLVLM8iiQe4mOeq7vqQj2AszQfR7sL2LAqoPqodf9b95hyZ8ZuybWFoiC9KSXnC38LlD8f9LYLRYB3p0056LYgEqa"
            :line-items="lineItems"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => stripeLoading = v"
          />
          <v-row dense>
            <v-col cols="12" align="center">
              <v-btn color="primary" x-large @click="submit">Add More Funds</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
    import { StripeCheckout } from '@vue-stripe/vue-stripe';
    import Moment from 'moment';
    import ApiService from "@/services/ApiService.js";

    export default {
        name: "MyWallet.vue",
        components: {
            StripeCheckout,
        },
        data() {
            return {
                pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
                items: [],
                loading: true,
                stripeLoading: false,
                totalAmount: 0,
                lineItems: [
                    {
                        price: 'price_1K7SesIKZFLVLM8ifmS8oj5X', // The id of the one-time price you created in your Stripe dashboard
                        quantity: 1,
                    },
                ],
                successURL: process.env.VUE_APP_ENDPOINT + '/stripe/success',
                cancelURL: process.env.VUE_APP_ENDPOINT + '/stripe/cancel',
            };
        },
        computed:{
            getUserID() {
                return this.$store.getters.getUserID;
            },
        },
        created(){
            this.load();
        },
        methods: {
            submit () {
                this.$root.snackbar.message = 'This is for demo purposes only!';
                this.$root.snackbar.open = true;
                // this.$refs.checkoutRef.redirectToCheckout();
            },
            formatDateTimeFull(date){
                return Moment(date).format("ddd MMM Do YYYY, h:mm A");
            },
            debitOrCredit(num){
                return (num < 0) ? 'Debit' : 'Credit';
            },
            getTotal(){
                this.totalAmount = this.items.map(item => item.amount).reduce((n, item) => item + n);
            },
            async load(){
                let self = this;
                let user_id = this.getUserID;
                return new Promise(() => {
                    ApiService
                        .post("/wallet/get-all", {
                            user_id: user_id
                        })
                        .then(response => {
                            self.items = response.data;
                            self.getTotal();
                            self.loading = false;
                        })
                        // .catch(error => {

                        // });
                });
            },
        },
    }
</script>

<style lang="scss">
  .mywallet{
    .debit{
      color: red;
    }
    .credit{
    }
    table{
      tfoot{
        th{
          text-align: right !important;
        }
      }
    }
  }
</style>
